import {
  ACCUSATIONS,
  CONSEQUENCES,
  FAQS,
  Procedures,
  Vacancies,
} from "../fixtures";
import { useState } from "react";
import examSchedule from "../images/ExamSchedule.png";
import hrwoExamSchedule from "../images/Exam_Date_of_HRWO.png";
import dwoAwoExamSchedule from "../images/Exam_Date_of_DWO_and_AWO.png";
import rwoItwoHrwoResult from "../images/rwo_itwo_hrwo_result.png";
import dwoResult1 from "../images/dwo_result1.png";
import dwoResult2 from "../images/dwo_result2.png";
import awoResult from "../images/awo_result.png";
import waitingList1 from "../images/waitingList1.png";
import waitingList2 from "../images/waitingList2.png";
import waitingList3 from "../images/waitingList3.png";
import waitingList4 from "../images/waitingList4.png";


export const Home = () => {
  const VacantList = () => {
    const vacancies = Vacancies();
    const [selectedIdx, setSelectedIdx] = useState<number | null>(null);

    const handleCheckboxChange = (index: number) => {
      setSelectedIdx(index === selectedIdx ? null : index);
    };

    return (
      <>
        <p className="justify-self-start text-md md:text-xl lg:text-2xl text-gray-600 font-extrabold">
          Click on any tile below to know more :-
        </p>
        {vacancies.map((item, index) => (
          <div
            key={index}
            tabIndex={0}
            className={`collapse collapse-plus border border-base-300 bg-base-200 mt-4 ${
              index === selectedIdx ? "selected" : ""
            }`}
          >
            <input
              type="checkbox"
              checked={index === selectedIdx}
              onChange={() => handleCheckboxChange(index)}
            />

            <div className="collapse-title text-lg font-bold md:text-xl bg-slate-300">
              {item.title}
            </div>
            <div className="collapse-content">
              <b>Qualification :-</b>
              <p>{item.qualifications}</p>
              <br />
              <b>Preference (Not mandatory)</b>
              <p>{item.preference}</p>
              <br />
              <b>Service Type :-</b>
              <p>{item.serviceType}</p>
              <br />
              <b>Salary :-</b>
              <p>{item.salary}</p>
              <br />
              <b>Promotion :-</b>
              <p>{item.promotion}</p>
              <br />
              <b>Pension Scheme :-</b>
              <p>{item.pensionScheme}</p>
              <br />
              <b>Mediclaim Facility :-</b>
              <p>{item.mediclaimFacility}</p>
              <br />
              <b>Group Term Insurance :-</b>
              <p>{item.groupTermInsurance}</p>
              <br />
              <b>Age Limit :-</b>
              <p>{item.ageLimit}</p>
              <br />
              <b>Language :-</b>
              <p>{item.language}</p>
              <br />
              <b>Job Responsibility :-</b>
              <p>{item.jobResponsibility}</p>
              <br />
              <b>Reporting :-</b>
              <p>{item.reporting}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  const ProcedureList: React.FC = () => {
    const [selectedIdx, setSelectedIdx] = useState<number | null>(null);

    const handleCheckboxChange = (index: number) => {
      setSelectedIdx(index === selectedIdx ? null : index);
    };

    return (
      <div>
        {Procedures.map((item, index) => (
          <div
            key={index}
            tabIndex={0}
            className={`collapse collapse-plus border border-base-300 bg-base-200 mt-4 ${
              index === selectedIdx ? "selected" : ""
            }`}
          >
            <input
              type="checkbox"
              checked={index === selectedIdx}
              onChange={() => handleCheckboxChange(index)}
            />
            <div className="collapse-title text-lg font-bold md:text-xl bg-slate-300">
              {item.title}
            </div>
            <div className="collapse-content">
              <ul>
                {item.descriptionList.map((description, i) => (
                  <div key={i}>
                    {i === 23 ? (
                      <>
                        <li>
                          &bull; A candidate who is or has been declared by the
                          Board to be guilty of:
                        </li>
                        <ol>
                          {/* List out accusations */}
                          {ACCUSATIONS.map((accusation, index) => (
                            <li key={index}>
                              {index + 1}. {accusation}
                            </li>
                          ))}
                        </ol>
                        <br />
                        <li>- may be liable:</li>
                        <br />
                        <ol>
                          {/* List out consequences */}
                          {CONSEQUENCES.map((consequence, index) => (
                            <li key={index}>
                              {index + 1}. {consequence}
                            </li>
                          ))}
                        </ol>
                      </>
                    ) : (
                      <li>&bull; {description}</li>
                    )}
                    <br />
                  </div>
                ))}
              </ul>
              {/* Centered text at the bottom */}
              {index === Procedures.length - 1 && (
                <div className="text-center font-bold mt-4">
                  By order of the Mission Directors, NHWM
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const FAQList = () => {
    return (
      <div
        tabIndex={0}
        className="collapse collapse-plus border border-base-300 bg-base-200 mt-4"
      >
        <input type="checkbox" />
        <div className="collapse-title text-lg font-bold md:text-xl bg-slate-300">
          FAQs for NHWM Job Advertisement
        </div>
        <div className="collapse-content">
          {FAQS.map((faq, index, array) => (
            <div key={faq.question}>
              <p>
                <b>Q :-</b> {faq.question}
              </p>
              <p>
                <b>A :-</b>
                {index === 0 ? (
                  <>
                    {faq.answer}
                    <a
                      className="text-[#08ACF2] font-bold"
                      href="/il"
                      target="_blank"
                    >
                      {" "}
                      Click here to see the letters.
                    </a>
                  </>
                ) : (
                  <>{faq.answer}</>
                )}
              </p>
              <br />
            </div>
          ))}
        </div>
      </div>
    );
  };
  const Syllabus = () => {
    return (
      <div
        tabIndex={0}
        className="collapse collapse-plus border border-base-300 bg-base-200 mt-4"
      >
        <input type="checkbox" />
        <div className="collapse-title text-lg font-bold md:text-xl bg-slate-300">
          OUTLINED SYLLABUS FOR BRT
        </div>
        <div className="collapse-content">
          <h1 className="text-md font-bold mb-2 mt-4">
            For DWO & AWO candidates:- Total Marks: 100
          </h1>
          <h3 className="text-md font-medium my-2">
            Part 1 – General Knowledge (20 Marks)
          </h3>
          <ul className="list-disc ml-4">
            <li>Indian History</li>
            <li>Indian Economy</li>
            <li>Indian Geography</li>
            <li>Indian Politics</li>
          </ul>
          <h3 className="text-md font-medium my-2">
            Part 2 – Special Paper (80 Marks)
          </h3>
          <h3 className="text-md font-medium my-2">For DWO-</h3>
          <ul className="list-disc ml-4">
            <li>IX & X standard General Science</li>
            <li>Principles of Human Nutrition</li>
          </ul>
          <h3 className="text-md font-medium my-2">For AWO -</h3>
          <ul className="list-disc ml-4">
            <li>VIII & IX Standard General Science</li>
            <li>Basics of Human Nutrition</li>
          </ul>
          <h1 className="text-md font-bold mb-2 mt-4">
            For RWO candidates:- Total 100 Marks
          </h1>
          <h3 className="text-md font-medium my-2">
            Part 1 – General Knowledge (20 Marks)
          </h3>
          <ul className="list-disc ml-4">
            <li>Indian History</li>
            <li>Indian Economy</li>
            <li>Indian Geography</li>
            <li>Indian Politics</li>
          </ul>
          <h3 className="text-md font-medium my-2">
            Part 2 – Special Paper (80 Marks)
          </h3>
          <ul className="list-disc ml-4">
            <li>Basics of Human Anatomy</li>
            <li>Basics of human Physiology</li>
            <li>Basics of Food & Nutritional Biochemistry</li>
            <li>Basics of Environmental Science</li>
            <li>Basics of Computer Application</li>
            <li>Basics of Leadership & Team Management</li>
          </ul>
          <h1 className="text-md font-bold mb-2 mt-4">
            For HRWO candidates:- Total 100 Marks
          </h1>
          <h3 className="text-md font-medium my-2">
            Part 1 – General Knowledge (20 Marks)
          </h3>
          <ul className="list-disc ml-4">
            <li>Indian History</li>
            <li>Indian Economy</li>
            <li>Indian Geography</li>
            <li>Indian Politics</li>
          </ul>
          <h3 className="text-md font-medium my-2">Part 2 – Special Paper</h3>
          <h3 className="text-md font-medium my-2">
            Option A (Human Resource) 80 Marks
          </h3>
          <ul className="list-disc ml-4">
            <li>Basics of Management Theory and Practice</li>
            <li>Basics of Manpower Planning, Recruitment and Selection</li>
            <li>Basics of Financial Accounting & Analysis</li>
            <li>Basics of Business Economics</li>
            <li>Basics of Information System for Managers</li>
            <li>Essentials of HRM</li>
            <li>Organization Culture and Organization Behaviour</li>
            <li>Industrial Relations & Labor Laws</li>
            <li>Performance Management System</li>
            <li>Marketing Management & Business Law</li>
          </ul>
          <h3 className="text-md font-medium my-2">
            Option B (Sales & Marketing) 80 Marks
          </h3>
          <ul className="list-disc ml-4">
            <li>Basics of Management Theory and Practice</li>
            <li>Basics of Corporate Social Responsibility</li>
            <li>Basics of Financial Accounting & Analysis</li>
            <li>Basics of Business Economics</li>
            <li>Basics of Information System for Managers</li>
            <li>Marketing Management & Marketing Strategy</li>
            <li>Organizational Behavior & Consumer Behavior</li>
            <li>Sales Management</li>
            <li>Business Communication</li>
            <li>Business Law</li>
          </ul>
          <h3 className="text-md font-medium my-2">
            Option C (Operations Management) 80 Marks
          </h3>
          <ul className="list-disc ml-4">
            <li>Basics of Management Theory and Practice</li>
            <li>Operations and Supply Chain Management</li>
            <li>Project Management</li>
            <li>Operations Research</li>
            <li>Basics of Information System for Managers</li>
            <li>Logistics and Distribution Management</li>
            <li>Lean Management</li>
            <li>Service Operations Management</li>
            <li>Business Process Management</li>
            <li>Procurement Management</li>
          </ul>
          <h3 className="text-md font-medium my-2">
            Option D (Leadership Management) 80 Marks
          </h3>
          <ul className="list-disc ml-4">
            <li>Basics of Management Theory and Practice</li>
            <li>Leadership Theories</li>
            <li>Leadership Development, Succession and Followership</li>
            <li>Leadership in Corporate Culture, Organization and Staffing</li>
            <li>Leadership Behavior and Motivation</li>
            <li>Leading with Effective Communication</li>
            <li>Leading High Performance Teams</li>
            <li>Negotiation and People Management</li>
            <li>Strategic Problem Solving</li>
            <li>Personal and Managerial Effectiveness</li>
          </ul>
          <h1 className="text-md font-bold mb-2 mt-4">
            For ITWO candidates:- Total 100 Marks
          </h1>
          <h3 className="text-md font-medium my-2">
            Part 1 – General Knowledge 20 Marks
          </h3>
          <ul className="list-disc ml-4">
            <li>Indian History</li>
            <li>Indian Economy</li>
            <li>Indian Geography</li>
            <li>Indian Politics</li>
          </ul>
          <h3 className="text-md font-medium my-2">
            Part 2 – Special Paper 80 Marks
          </h3>
          <ul className="list-disc ml-4">
            <li>Computer fundamentals</li>
            <li>Programming language (JavaScript & Typescript)</li>
            <li>Data Structure and Algorithms</li>
            <li>Database Management Systems</li>
            <li>Organizational Behaviour</li>
            <li>E-commerce & Web Designing</li>
            <li>System Administration</li>
            <li>Networking Essentials & Computer Security</li>
            <li>Discrete Mathematics</li>
            <li>Quality Assurance (QA)</li>
          </ul>
        </div>
      </div>
    );
  };

  const ExamPattern = () => {
    return (
      <div
        tabIndex={0}
        className="collapse collapse-plus border border-base-300 bg-base-200 mt-4"
      >
        <input type="checkbox" />
        <div className="collapse-title text-lg font-bold md:text-xl bg-slate-300">
          EXAM PATTERNS
        </div>
        <div className="collapse-content text-lg">
          <p className="font-bold text-xl my-2">
            Exam will be in <span className="text-xl italic">TWO SECTIONS</span>
            (no break/gap will be there in between the sections)
          </p>

          <p className="font-bold ml-4">Section 1 :- Duration 30 minutes</p>
          <p>
            <span className="font-bold ml-8">Only MCQ</span> from both the parts
            of the Syllabus ( 10 Questions from Part -1, 30 Questions from Part
            - 2)
          </p>
          <p className="font-bold mt-3 ml-4">Section 2:- Duration 60 minutes</p>
          <p>
            <span className="font-bold ml-8">Short Questions</span> from both
            the parts of the Syllabus ( 5 Questions from Part - 1, 10 Questions
            from Part - 2 )
          </p>
          <p>
            <span className="font-bold ml-8">Long Questions</span> only from
            Part - 2 of the Syllabus (6 Questions)
          </p>
          <p className="font-bold text-xl my-3">Mode of Exam is Online-</p>
          <p className="font-bold my-2">Rules of Exam :-</p>
          <ul className="list-disc mx-3 text-xl font-bold leading-7 ml-8">
            <li className="my-1">
              Appearing the exam in MOBILE or TABLET is not allowed. (Exam is
              incompatible to Mobile or Tablet)
            </li>
            <li className="my-1">
              No top-up of time allotted for 2nd Sections of exam if you finish
              the 1st Section earlier.
            </li>
            <li className="my-1">
              Minimizing the main Exam Window will not be allowed during
              examination.
            </li>
            <li className="my-1">
              No video and audio OFF is allowed during Exam.
            </li>
            <li className="my-1">
              No other person will be allowed in you examination room.
            </li>
            <li className="my-1">
              After submitting the paper, take permission from the Proctor to
              leave the Zoom.
            </li>
            <li className="my-1">
              If a candidate has submitted the paper without connecting in Zoom
              will not be accepted/evaluated.
            </li>
            <li className="my-1">
              Blur background or using a background wall-paper is not allowed in
              Zoom.
            </li>
            <li className="my-1">
              You have to show us your Room, Table and Mobile (where you have
              kept it) by moving your Laptop or Webcam.
            </li>
            <li className="my-1">
              Your video will be recorded. If any suspicious activities are
              observed during the exam, it may result in disqualification from
              the final interview round due to a lack of honesty.
            </li>
            <li className="my-1">
              A few other rules are mentioned or will be mentioned on your ADMIT
              CARD that must be followed by each and every candidate.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const ImportantDates = () => {
    return (
      <div
        tabIndex={0}
        className="collapse collapse-plus border border-base-300 bg-base-200 mt-4"
      >
        <input type="checkbox" defaultChecked />
        <div className="collapse-title text-Xl font-bold md:text-2xl bg-slate-300">
          IMPORTANT INFORMATIONS REGARDING BRT
        </div>
        <div className="collapse-content flex flex-col gap-3 md:text-lg">
          <div className="flex gap-2 justify-center items-center">
            <span className="motion-safe:animate-pulse animate-blink transition-all delay-1000">
              <svg
                className="text-red-500 bg-red-500 rounded-full"
                width="36px"
                height="36px"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>new-star-solid</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="invisible_box" data-name="invisible box">
                    <rect width="48" height="48" fill="none" />
                  </g>
                  <g id="Q3_icons" data-name="Q3 icons">
                    <path d="M42.3,24l3.4-5.1a2,2,0,0,0,.2-1.7A1.8,1.8,0,0,0,44.7,16l-5.9-2.4-.5-5.9a2.1,2.1,0,0,0-.7-1.5,2,2,0,0,0-1.7-.3L29.6,7.2,25.5,2.6a2.2,2.2,0,0,0-3,0L18.4,7.2,12.1,5.9a2,2,0,0,0-1.7.3,2.1,2.1,0,0,0-.7,1.5l-.5,5.9L3.3,16a1.8,1.8,0,0,0-1.2,1.2,2,2,0,0,0,.2,1.7L5.7,24,2.3,29.1a2,2,0,0,0,1,2.9l5.9,2.4.5,5.9a2.1,2.1,0,0,0,.7,1.5,2,2,0,0,0,1.7.3l6.3-1.3,4.1,4.5a2,2,0,0,0,3,0l4.1-4.5,6.3,1.3a2,2,0,0,0,1.7-.3,2.1,2.1,0,0,0,.7-1.5l.5-5.9L44.7,32a2,2,0,0,0,1-2.9ZM18,31.1l-4.2-3.2L12.7,27h-.1l.6,1.4,1.7,4-2.1.8L9.3,24.6l2.1-.8L15.7,27l1.1.9h0a11.8,11.8,0,0,0-.6-1.3l-1.6-4.1,2.1-.9,3.5,8.6Zm3.3-1.3-3.5-8.7,6.6-2.6.7,1.8L20.7,22l.6,1.6L25.1,22l.7,1.7L22,25.2l.7,1.9,4.5-1.8.7,1.8Zm13.9-5.7-2.6-3.7-.9-1.5h-.1a14.7,14.7,0,0,1,.4,1.7l.8,4.5-2.1.9-5.9-7.7,2.2-.9,2.3,3.3,1.3,2h0a22.4,22.4,0,0,1-.4-2.3l-.7-4,2-.8L33.8,19,35,20.9h0s-.2-1.4-.4-2.4L34,14.6l2.1-.9,1.2,9.6Z" />
                  </g>
                </g>
              </svg>
            </span>
            <h3 className="text-xl font-semibold text-red-500 text-center my-3">
              Results of BRT have been declared. Please scroll down to the Result
              Section for checking your Result.
            </h3>
          </div>
          <div className="p-3 bg-slate-200 flex-col flex rounded-md gap-y-3 mb-3 mt-10">
            <span className="text-black font-bold uppercase">
              Login Credentials :–
            </span>
          </div>

          <div className="flex-col">
            <span className="ml-4 font-bold">RWO</span>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Assam and Northeast States - `}
              <b>Already shared through Email</b>
            </li>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Rest of the States of India - `}
              <b>Already shared through Email</b>
            </li>
          </div>
          <div className="flex-col">
            <span className="ml-4 font-bold">ITWO & HRWO</span>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Assam and Northeast States - `}
              <b>Already shared through Email</b>
            </li>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Rest of the States of India -  `}
              <b>Already shared through Email</b>
            </li>
          </div>
          {/* DWO & AWO */}
          <div className="flex-col">
            <span className="ml-4 font-bold">DWO & AWO</span>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Assam and Northeast States - `}
              <b>Already shared through Email</b>
            </li>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Rest of the States of India -  `}
              <b>Already shared through Email</b>
            </li>
          </div>
          <div className="flex justify-center flex-col">
            <p className="text-lg text-center font-bold italic">
              Candidates are requested to check their mails for Login
              Credentials
            </p>
            <p className="text-md text-center font-semibold italic">
              Please search in the Email Search bar as NHWM
            </p>
          </div>
          <div className="p-3 bg-slate-200 flex-col flex rounded-md gap-y-3 mb-3 mt-10">
            <span className="text-black font-bold uppercase">
              Access to the Exam Portal :–
            </span>
          </div>

          <div className="flex-col">
            <span className="ml-4 font-bold">RWO, ITWO & HRWO</span>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Assam and Northeast States - `}
              <b>Access Approved</b>
            </li>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Rest of the States of India - `}
              <b>Access Approved</b>
            </li>
          </div>
          {/* DWO & AWO */}
          <div className="flex-col">
            <span className="ml-4 font-bold">DWO & AWO</span>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Assam and Northeast States - `}
              <b>Access Approved</b>
            </li>
            <li className="md:ml-8 text-[13px] md:text-base">
              {`Rest of the States of India -  `}
              <b>Access Approved</b>
            </li>
          </div>
          {/* section */}
          <div className="p-3 bg-slate-200 flex-col flex rounded-md gap-y-3 mb-3 mt-10">
            <span className="text-black font-bold uppercase">
              dates of Basic Recruitment Test :–
            </span>
          </div>

          {/* RWO, ITWO & HRWO */}
          <div className="flex flex-col font-bold gap-y-3">
            <div>
              <p className="ml-4">RWO</p>
              <ul className="list-disc ml-12">
                <li>
                  Assam - 14th, 17th, 22nd and 26th ( 2 batches clubbed )
                  September 2024
                </li>
                <li>NE states - 30th September & 1st October 2024</li>
              </ul>
              <h1 className="underline mt-3 ml-4 text-center text-xl">
                RWO Rest of the States of India and ITWO-
              </h1>
              <img
                src={examSchedule}
                alt="exam schedule"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
              <h1 className="mt-3 ml-4 text-center text-xl underline">
                HRWO EXAM SCHEDULE FOR ALL INDIA
              </h1>
              <img
                src={hrwoExamSchedule}
                alt="HRWO exam schedule"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
              <h1 className="mt-3 underline ml-4 text-center text-xl">
                DWO & AWO EXAM SCHEDULE FOR ALL INDIA
              </h1>
              <img
                src={dwoAwoExamSchedule}
                alt="DWO & AWO exam schedule"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
            </div>
            <p className="text-center mt-4">
              We send Email for guiding candidates - how to schedule the Exam
              Slot/s. Candidates are requested to check their email time to time
              in the registered email id.
            </p>
          </div>

          {/* section */}
          <div className="p-3 bg-slate-200 flex-col flex rounded-md gap-y-3 mb-3 mt-10">
            {" "}
            <span className="text-black font-bold uppercase">
              Result Declaration :–
            </span>
          </div>

          {/* RWO, ITWO & HRWO */}
          <div className="">
            <div className="mx-auto">
              <img
                src={rwoItwoHrwoResult}
                alt="qualified candidates"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
              <img
                src={dwoResult1}
                alt="qualified candidates"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
              <img
                src={dwoResult2}
                alt="qualified candidates"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
              <img
                src={awoResult}
                alt="qualified candidates"
                className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
              />
            </div>
            <div className="mt-4 border-t-2 border-gray-400">
              <h3 className="text-2xl font-bold text-center mt-3">Waiting List of Candidates</h3>
              <div>
                <img
                  src={waitingList1}
                  alt="waiting list candidates"
                  className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
                />
                <img
                  src={waitingList2}
                  alt="waiting list candidates"
                  className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
                />
                <img
                  src={waitingList3}
                  alt="waiting list candidates"
                  className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
                />
                <img
                  src={waitingList4}
                  alt="waiting list candidates"
                  className="max-w-full md:max-w-[1080px] mx-auto my-8 aspect-[3/4]"
                />
              </div>
            </div>
          </div>

          <div className="p-3 bg-slate-200 flex-col flex rounded-md gap-y-3 mb-3 mt-10">
            <span className="text-black font-bold">
              Date of Viva for the Selected Candidates:–
            </span>
          </div>
          <span>TBA</span>
          <div className="p-3 bg-slate-200 flex-col flex rounded-md gap-y-3 mb-3">
            <span className="text-black font-bold">
              Start of Training for Selected Candidates :–
            </span>
          </div>
          <span>TBA</span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center items-center flex-col gap-4 pl-5 pr-5 pb-5 navbar-offset">
      <div>
        <div className="mt-10 mb-5">
          <div className="motion-safe:animate-pulse transition-all delay-1000 text-[15px] font-bold text-red-500 animate-blink text-center flex flex-col gap-y-2 my-7">
            <p>
              DECLARATION TO ALL CANDIDATES :- All documents collected as soft
              copies or handwritten samples with the BRT Application Form are
              solely for verification purposes. We assure to all candidates that
              NHWM will never misuse those documents. Candidates are requested
              not to misunderstand this process.
            </p>
          </div>

          <ImportantDates />
          <Syllabus />
          <ExamPattern />
          <p className="text-base md:text-3xl text-center font-extrabold text-gray-600 mt-10">
            Application submission process is over for Basic Recruitment Test
            2023-24 <br />
            to fill-up the following post in <br /> National Healthcare and
            Wellness Mission (NHWM)
          </p>
        </div>
        <p className="text-lg md:text-2xl text-gray-600 text-center mt-5 mb-5">
          Total Number of Vacant Posts - 3200 and will be filled state-wise
          gradually
        </p>
      </div>

      <div className="container">{VacantList()}</div>
      <div className="container">
        <ProcedureList />
        <FAQList />
      </div>

      <div className="flex justify-center my-2">
        <div className="max-w-md mx-auto text-white bg-[#08ACF2] hover:bg-[hsl(198,94%,39%)] p-2 border border-blue-700 rounded-md text-sm md:text-xl">
          <a
            href="https://www.facebook.com/NHWMOH"
            target="_blank"
            rel="noopener noreferrer"
          >
            &#10153; Click here to Follow our Facebook Page
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-3 justify-center xl:flex-row xl:justify-between-between xl:w-full py-5">
        <a
          href="/il"
          className="bg-[#08ACF2] p-2 rounded-md hover:bg-[hsl(198,94%,39%)] text-white text-sm md:text-xl"
        >
          &#10153; See the Intimations sent to various Govt. Dept.
        </a>
      </div>
    </div>
  );
};
